.app__navbar-float{
    background: rgba(0,0,0,0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);

    @media screen and (min-width: 900px){
        display: none;
    }

    a {
        background: transparent;
        padding: 0.7rem;
        border-radius: 50%;
        display:flex;
        color: var(--primary-color);
        font-size: 1.2rem;

        &:hover {
            background: rgba(0,0,0,0.3);
            transition: all 0.2s ease-in-out;
        } 

    }

    .active {
        background: var(--secondary-color);
    }
}