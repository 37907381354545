.app__navbar {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1.5rem 2rem;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px); //blur the background on scroll
    -webkit-backdrop-filter: blur(4px); //for old browsers

    border: 1px solid rgba(255, 255, 255, 0.18);
    position: fixed;
    z-index: 2;

    @media screen and (max-width: 900px){
        display: none;
    }
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
        width: 30%;
        height: 30%;

        @media screen and (min-width:2000px){
            width: 50%;
            height: 50%;
        }
    }
}



.app__navbar-links {
    flex: 1; //take the space the logo doesn't take
    display: flex; //to make it in a row 
    justify-content: center;
    align-items: center;

    list-style: none;

    li{
        margin: 0 1rem;
        cursor: pointer; //to make it look clickable

        flex-direction: column;

        

        a{
            color: black;
            text-decoration: none;
            flex-direction: column;

            text-transform: uppercase;
            font-weight: bold; //500 for bolder
            padding-inline: 0.8rem;

            transition: all 0.2s ease-in-out;

           &:hover {
               color: var(--secondary-color);
           } 
        }

        &:hover {
            div {
                background: var(--secondary-color);
                transition: all 0.2s ease-in-out;
            }
        }
    }

    @media screen and (max-width: 900px) {
        display: none;
    }
}

.app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--secondary-color);

    svg {  //for the menu bars color
        width:70%;
        height: 70%;
        color: var(--white-color);
    }

    div {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;

        padding: 1rem;

        width: 80%;
        height: 100vh;

        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;

        background: url('../../assets/bgWhite.png');
        background-color: var(--white-color);
        background-size: cover;
        background-repeat: repeat;

        box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

        

        svg {  //for the menu close
            width: 35px;
            height: 35px;
            color: var(--secondary-color);

            margin: 0.5rem 1rem;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;

            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            li{
                margin: 1rem;

                a {
                    color: var(--gray-color);
                    text-decoration: none;
                    font-size: 1rem;
                    text-transform: uppercase;
                    font-weight: 500;

                    transition: all 0.2s ease-in-out;

                    &:hover {
                        color: var(--secondary-color);
                    } 
                }
            }

        }

        @media screen and (min-width: 900px){
            display: none;
        }
    }

    @media screen and (min-width: 900px){
        display: none;
    }
}

button {
    padding: 1rem 2rem;
    border-radius: 10px;
    border: none;
    background-color: var(--secondary-color);

    font-weight: 500;
    color: var(--white-color);
    outline: none;
    font-family: var(--font-base);
    display: flex;

    transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    cursor: pointer;

    &:hover {
      background-color: #2430af;
    }
  }