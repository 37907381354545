.parentss {
    padding-top: 5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 110px;
    grid-row-gap: 0px;

    @media screen and (max-width: 1200px) {
        display: block;
        text-align: center;
    }
}

.div1ss {

    a{
        text-align: -webkit-center;
    }
    button {
        padding: 1rem 2rem;
        border-radius: 10px;
        border: none;
        background-color: var(--secondary-color);
        text-decoration: none;
        font-weight: 500;
        color: var(--white-color);
        outline: none;
        margin: 2rem 0 0 0;
        font-family: var(--font-base);

        transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
        cursor: pointer;

        &:hover {
            background-color: #5d1426;
            transition: all 0.2s ease-in-out;
        }

        b {
            color: var(--secondary-color);
        }
    }

        grid-area: 1 / 1 / 2 / 2;
        text-align: justify;
    }


    .div2ss {
        grid-area: 1 / 2 / 2 / 3;
        img{
            padding-top: 80px;
            width: 70%;
            height:fit-content;
        }
    }