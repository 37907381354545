.app__footer {
  flex: 1;
  width: 100%;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.app__footer-all {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch;

  @media screen and (max-width: 768px) {
    width: 100%;
    display: contents;
  }
}

.app__footer-profile {
  position: relative;

  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    @media screen and (max-width: 768px) {
      max-height: 80%;
      width: auto;
      height: auto;
      position: relative;
      margin-top: 2em;
    }

    /*
    width: 80%;
    height: 80%;
    object-fit: contain;
    overflow: hidden;
    */
  }
}

.app__footer-cards {
  width: 95%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 4em 2rem 2rem;

  .app__footer-card {
    min-width: 290px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin: 1rem 0;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;

    transition: all 0.3s ease-in-out;

    img {
      width: 40px;
      height: 40px;
      margin: 0 0.7rem;
    }

    p {
      font-weight: 500;
    }

    a {
      text-decoration: none;
      font-weight: 500;
    }

    &:hover {
      box-shadow: 0 0 25px #f2f7fb;
    }

    @media screen and (max-width: 450px) {
      width: 80%;
    }
  }

  @media screen and (max-width: 768px) {
    width: 80%;
    margin: -2em 2rem 2rem;
  }
}

.app__footer-cards .app__footer-card:last-child {
  background-color: white;
}

.app__footer-cards .app__footer-card:last-child:hover {
  box-shadow: 0 0 25px #f2f7fb;
}

.app__footer-form {
  width: 80%;
  flex-direction: column;
  margin: 1rem 5rem;

  .app__buttons {
    display: flex;
    width: 80%;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;

    a{
      text-decoration: none;
    }
  }

  div {
    width: 100%;
    display: flex;

    margin: 0.75rem 0;
    border-radius: 10px;
    cursor: pointer;
    /*background-color: var(--primary-color);*/

    transition: all 0.3s ease-in-out;

    input,
    textarea {
      width: 100%;
      padding: 0.95rem;
      border: none;
      border-radius: 7px;
      background-color: white;

      font-family: var(--font-base);
      color: var(--secondary-color);
      outline: none;
    }

    textarea {
      height: 170px;
    }

    &:hover {
      box-shadow: 0 0 25px var(--primary-color);
    }
  }

  button {
    padding: 1rem 2rem;
    border-radius: 10px;
    border: none;
    background-color: var(--secondary-color);

    font-weight: 500;
    color: var(--white-color);
    outline: none;
    margin: 2rem 0 0 0;
    font-family: var(--font-base);

    transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    cursor: pointer;

    &:hover {
      background-color: #5d1426;
      transition: all 0.2s ease-in-out;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
  }

  .app__social-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1.5rem;
    padding: 1rem;

    a{
      padding: 15px 15px;
    }
  
    div {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--white-color);
      margin: 0.25rem 0;
      border: 1px solid var(--lightGray-color);
  
      display: flex;
      justify-content: center;
      align-items: center;
  
      transition: all 0.3s ease-in-out;
  
      svg {
        width: 15px;
        height: 15px;
        color: var(--gray-color);
      }
  
      &:hover {
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
  
        svg {
          color: var(--white-color);
        }
      }
    }

    @media screen and (min-width: 900px) {
        display: none;
    }
  }
}